/**
 * 文章管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取文章分类列表
export const getArticleTypeListApi = (params) => {
    return $http({
        url: '/admin/getArticleTypeList.html',
        method: 'GET',
        params
    });
};

// 新建文章分类
export const addArticleTypeApi = (data) => {
    return $http({
        url: '/admin/addArticleCat.html',
        method: 'POSt',
        data
    });
};

// 编辑文章分类
export const editArticleTypeApi = (data) => {
    return $http({
        url: '/admin/editArticleCat.html',
        method: 'POSt',
        data
    });
};

// 删除文章分类
export const deleteArticleTypeApi = (params) => {
    return $http({
        url: '/admin/delCat.html',
        method: 'GET',
        params
    });
};


// 获取文章列表
export const getArticleListApi = (params) => {
    return $http({
        url: '/admin/getArticleList.html',
        method: 'GET',
        params
    });
};

// 新建文章
export const createArticleApi = (data) => {
    return $http({
        url: '/admin/addArticle.html',
        method: 'POST',
        data
    });
};

// 编辑文章
export const editArticleApi = (data) => {
    return $http({
        url: '/admin/editArticle.html',
        method: 'POST',
        data
    });
};

// 获取文章分类下拉选项列表
export const getArticleCatOptionsApi = (params) => {
    return $http({
        url: '/admin/getArticleCatSelect.html',
        method: 'GET',
        params
    });
};

// 删除文章
export const deleteArticleApi = (params) => {
    return $http({
        url: '/admin/delArticle.html',
        method: 'GET',
        params
    });
};

// 获取文章详情
export const getOneArticleDetailApi = (params) => {
    return $http({
        url: '/admin/getArticle.html',
        method: 'GET',
        params
    });
};

// 发布文章
export const publishArticleApi = (params) => {
    return $http({
        url: '/admin/publicArticle.html',
        method: 'GET',
        params
    });
};

// 取消文章发布
export const unPublishArticleApi = (params) => {
    return $http({
        url: '/admin/delPublicArticle.html',
        method: 'GET',
        params
    });
};

// 上传附件
export const api_uploadFile = (params) => {
    return $http({
        url: '/admin/getArticleAttachmentUpload.html',
        method: 'GET',
        params
    });
};